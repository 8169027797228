// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      fetch.js
// Created on:    2019/10/07
// Last modified: 2021/05/07
// ============================================================================

import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';

export const computeBaseURL = () => {
  if (_.includes(window.location.origin, 'localhost:8080')) {
    return 'http://localhost:9090';
  } else {
    return window.location.origin;
  }
};

const fetchRefreshToken = async (refreshToken) => {
  let accessToken = '';
  try {
    const resp = await fetch(`${computeBaseURL()}/api/v1.0/refresh`, {
      method: 'post',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        refreshToken,
      }),
    });
    const json = await resp?.json();
    accessToken = json?.data[0]?.accessToken;
  } catch (err) {
    throw new Error('Failed to refresh token:', err);
  }
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    throw new Error('Failed to refresh token');
  }

  return accessToken;
};

export const fetchWithTimeout = async (uri, options = {}, time = 5000) => {
  // Lets set up our `AbortController`, and create a request options object
  // that includes the controller's `signal` to pass to `fetch`.
  const controller = new AbortController();
  let config = { ...options, signal: controller.signal };

  // Set a timeout limit for the request using `setTimeout`. If the body of this
  // timeout is reached before the request is completed, it will be cancelled.
  setTimeout(() => {
    controller.abort();
  }, time);

  let accessToken = localStorage.getItem('accessToken');
  // use the accessToken if stored
  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    const now = Date.now() / 1000;
    const refreshToken = localStorage.getItem('refreshToken');
    // refresh token if expired
    if (now >= decoded.exp - 60 && refreshToken) {
      accessToken = await fetchRefreshToken(refreshToken).catch((err) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.assign('/login');
        return null;
      });
    }

    // Add the authorization header with the stored token
    if (_.isNil(config.headers)) {
      config = {
        ...config,
        headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
      };
    } else {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }
  }

  const url = computeBaseURL() + uri;
  return fetch(url, config)
    .then(async (response) => {
      // Because _any_ response is considered a success to `fetch`,
      // we need to manually check that the response is in the 200 range.

      if (response.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.assign('/login');
        return null;
      } else if (!response.ok) {
        const msg = await response.text();
        if (msg.length > 0) {
          throw new Error(msg);
        } else {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
      }
      return response;
    })
    .catch((error) => {
      // When we abort our `fetch`, the controller conveniently throws a named
      // error, allowing us to handle them seperately from other errors.
      if (error.name === 'AbortError') {
        throw new Error('Server response timed out !');
      }
      throw new Error(error.message);
    });
};
